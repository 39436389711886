import Vue from 'vue'
import VueRouter from 'vue-router'
import Home_page from '../views/home_page'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  { path: '/', name: 'Home_page', component: Home_page },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
