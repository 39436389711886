<template>
  <div>
    <h3>{{ time_1 }}</h3>
    <button @click="test()">msf</button>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "Home_page",
  data() {
    return {
      time_1: 0,
      API_KEY: "c778c04255f560bcdb5bbf2da82e7fa5",
      API_SECRET: "450d05492338837586db45ac4a2bda97",
      url_bit: "https://api.bitkub.com",
    };
  },
  methods: {
    async test() {
      const headers = { "Content-Type": "application/json" };
      let url_1 = "https://runbitkub.augsorn.work/get_time.php";

      await Axios.get(url_1)
        .then((res) => {
          this.time_1 = res.data.time;
          // console.log(res.data.time);
        })
        .catch((err) => {
          console.log(err);
        });

      let gg = {
        ts: this.time_1,
      };
      let msg_json = JSON.stringify(gg);
      // console.log(msg_json);

      var hash = this.CryptoJS.HmacSHA256(msg_json, this.API_SECRET).toString();
      console.log(hash);

      let dataSend = {
        ts: this.time_1,
        sig: hash,
      };
      dataSend = JSON.stringify(dataSend);
      console.log(dataSend);
      // return false;

      const Headers = {
        Accept: "application/json",
        "Content-type": "application/json",
        "X-BTK-APIKEY": this.API_KEY,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      };

      // console.log(Headers);
      // return false;

      await Axios.post(this.url_bit + "/api/market/balances", dataSend, {
        headers: Headers,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>


<style scoped>
</style>
